
.loader{
    position: fixed;
    left: 48%;
    top: 40%;
}

.loader-message{
    margin-top: -20px;
    margin-left: -5px;
    font-family: 'Open Sans', sans-serif;
}