.modal-content{
    border-radius: 0px;

    .btn-form-group{
        float: right;
        padding-right: 10px !important;
    }

    .modal-header{
        border-bottom: none;
        border-radius: 0px;
        padding-left:30px;
        
        .close{
            font-size: 40px;
            padding-top: 8px;
        }
    }

    .modal-content .table-content .table thead th{
        &:focus{
            outline:none;
        }
      
        &:active{
            outline:none;
        }
    }

    .scrollableList{
        max-height: 300px;
        overflow: overlay;
        margin-bottom: 20px;
    }

    .badge{
        padding: 6px;
        font-size: 12px;
        margin-left: 6px;
        background-color: $default-red;
        letter-spacing: 0;
        line-height: 18px;
        font-family: 'Open Sans', sans-serif;
    }

    .badge-label{
        color: $default-gray;
        letter-spacing: 0;
        line-height: 18px;
        font-family: 'Open Sans', sans-serif;
    }

    .closeBadge{
        cursor: pointer;
        &:hover{
            cursor: pointer;
            color: $default-cancel-gray;
        }
    }

    .first-form-element{
        padding-left: 26px !important;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }
      
    ::-webkit-scrollbar-track {
        background: $default-white; 
    }
       
    ::-webkit-scrollbar-thumb {
        background: $default-cancel-gray; 
    }
      
    ::-webkit-scrollbar-thumb:hover {
        background: $default-gray; 
    }    

    .modal-list-filters{
        padding-left:20px;
        padding-right:20px;

        label{
            color: $default-gray;
            font-family: 'Open Sans', sans-serif;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 0px;
            margin-top: 8px;
        }

        .delete-filters-btn{
            display:block;
            color: $default-gray;
            font-size: 220%;
            margin-top: -5px;
            margin-left: 0px;
            margin-bottom: 24px;
            max-width: 46px;
            cursor: pointer !important;
        }
    }

    .table-content{
        margin-left: 24px;
        margin-right: 24px;
        padding-bottom: 10px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
    
        #pageDropDown{
            .btn-secondary{
                background-color: $default-gray;
                border-color: $default-gray;
            }
        }
    
        .dropdown-item{
            &:hover{
                background-color: $default-red;
                color:$default-white;
            }
        }

        .list-item-selected{
            background-color: $table-row-hover;
            &:hover{
                background-color: $table-row-hover;
            }
        }
    
        .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid $default-cancel-gray;
            border-top: 1px solid $default-cancel-gray;
            color: $default-gray;
            font-weight: bold;
        }
    
        .table td{
            border-color: $default-cancel-gray;
            color: $table-row;
        }
    
        .table{
            tr:hover td{
                background-color: $table-row-hover;
                cursor: pointer;
            }
        }
    
        .page-item{
            color: $default-gray;
            &:focus{
                outline:none;
            }
          
            &:active{
                outline:none;
            }
        }
    
        .page-link{
            color: $default-gray;
            &:focus{
                outline:none;
            }
          
            &:active{
                outline:none;
            }
        }
    
        .page-item.active .page-link{
            z-index: 3;
            color: $default-white;
            background-color: $default-red;
            border-color: $default-red;
        }
    
        .sortable{
            &:focus{
                outline:none;
            }
          
            &:active{
                outline:none;
            }
        }
    }

}
