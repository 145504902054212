// 
// page-head.scss
//

.page-title-box {
    
    background-color: $breadcrumb-bg;
    padding-left: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 4px;

    .page-title {
        color: $page-title-color;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
    .page-title-right {
        float: right;
        margin-top: -32px;
        margin-right: 24px;
    }

    .breadcrumb-current {
        padding-top: 8px;
        color: $default-gray;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: right;
    }

    .breadcrumb-elem {
        padding-top: 8px;
        color: $default-red;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: right;
    }

    .breadCrumbSeparator{
        padding-left: 20px;
        padding-right: 20px;
    }
}

.page-title-box-sm {
    .page-title {
        line-height: 1 !important;
        margin-bottom: 25px;
    }

    .page-title-right {
        float: right;
        margin-top: 0;
    }

    .breadcrumb {
        padding-top: 0;
        margin-top: -3px !important;
    }
}


.text-title {
    color: $text-title-color;

    &:hover {
        color: $text-title-color;
    }
}

@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}