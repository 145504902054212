@media (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
    overflow-y: auto;
  }
}
@media (min-width: 767px) {
  .table-responsive {
    overflow: inherit !important; /* Sometimes needs !important */
  }
}
