.main-content {
    background-color: #FFFFFF;
    margin-top: 20px;
    margin-left: 0px;
    border-radius: 4px;

    .col {
        padding-left: 0px;
    }

    .alert {
        margin-left: 30%;
        margin-right: 30%;

        p {
            margin-bottom: 0px;
        }

        .close {
            padding-top: 10px;
        }
    }

    select option {
        color: $default-gray;
    }

    select:focus {
        background-color: $default-focus-select-bgcolor;
    }

    textarea:focus {
        background-color: $default-focus-select-bgcolor;
    }

    input[type=text]:focus {
        background-color: $default-focus-select-bgcolor;
    }

    input[type=date]:focus {
        background-color: $default-focus-select-bgcolor;
    }

    input[type=number]:focus {
        background-color: $default-focus-select-bgcolor;
    }

    select:invalid {
        color: $default-select-color;
    }

    .rbt-close {
        margin-top: -30px;
        margin-right: 10px;
    }

    .alert-danger {
        background-color: $default-red;
        color: $default-white;
    }

    .alert-success {
        background-color: $default-green;
        color: $default-white;
    }

    .new-user-subheader {
        color: $default-gray;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        letter-spacing: 0.29px;
        line-height: 16px;
        margin-top: 24px;
        margin-left: 24px;
        margin-bottom: 46px;
    }

    .plus-btn {
        display: block;
        color: $default-gray;
        font-size: 300%;
        /*        margin-top: -10px;
        margin-left: 46px;
        margin-bottom: 24px;
        max-width: 46px;*/
        cursor: pointer !important;
    }

    .btn-form-group {
        float: right;
        margin-right: 40px !important;
    }

    .form-col-btn-icon {
        display: contents;
        color: $default-gray;
        font-size: 150%;
        margin-top: -10px;
        margin-left: 46px;
        margin-bottom: 24px;
        max-width: 46px;
    }

    .form-col-btn-text {
        vertical-align: top;
        padding-left: 5px;
        color: $default-gray;
    }

    .open-modal-btn {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        color: $default-gray;
        font-weight: bold;
        font-size: 14px;
        border: 1px solid $default-gray;
        line-height: 19px;
        text-align: center;
        cursor: pointer;
        border-radius: 30px;
        background-color: $default-white;
        padding-top: 8px;
        padding-bottom: 6px;
        padding-left: 16px;
        padding-right: 16px;

        &:focus {
            border: 1px solid $default-select-color !important;
            border-radius: 30px;
            background-color: $body-bg;
        }

        &:hover {
            border: 1px solid $default-select-color !important;
            border-radius: 30px;
            background-color: $body-bg;
        }
    }

    .margin-left-20px {
        margin-left: 20px;
    }

    .margin-left-24px {
        margin-left: 24px;
    }

    .margin-left-34px {
        margin-left: 34px;
    }

    .margin-top-24px {
        margin-top: 24px;
    }

    .error-msg {
        color: $error-msg;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
    }

    .table-error-msg {
        color: $error-msg;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        margin-top: -10px
    }

    .checkbox-col-form {
        margin-top: 36px;
        cursor: pointer;
    }

    .form-check-label {
        margin-left: 10px;
        vertical-align: initial;
    }

    .form-col-btn {
        margin-top: 28px;
    }

    .delete-filters-btn {
        display: block;
        color: $default-gray;
        font-size: 220%;
        margin-top: -5px;
        margin-left: 0px;
        margin-bottom: 24px;
        max-width: 46px;
        cursor: pointer !important;
    }

    .delete-filters-padding-top-btn {
        display: block;
        color: $default-gray;
        font-size: 220%;
        margin-top: -5px;
        margin-left: 0px;
        margin-bottom: 24px;
        max-width: 46px;
        cursor: pointer !important;

        &:before {
            padding-top: 36px;
        }
    }

    .header-app {
        margin-top: 36px;
        color: $default-gray;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
    }

    .header-filter-app {
        color: $default-gray;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;

        input[type="radio"] {
            margin-left: 4px;
            margin-right: 18px;
            cursor: pointer;
        }
    }

    .first-header-element {
        padding-left: 40px;
    }

    .last-header-element {
        padding-right: 40px;
    }

    .first-form-element {
        padding-left: 24px !important;
    }

    .no-padding-right {
        padding-right: 0px;
    }

    .padding-right-header {
        padding-right: 66px;

        label {
            text-align: end;
            padding-top: 8px;
            padding-right: 11px;
        }
    }

    .divider {
        height: 2px;
        width: 98.5%;
        opacity: 0.2;
        background-color: #999999;
        margin-top: 46px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .accordion {
        width: 98.5%;
        margin-top: 30px;
        border: none;
        border-radius: 0px;

        .card {
            border: none;
            border-radius: 0px;
        }

        .card-header {
            cursor: pointer;
            border: none;
            background-color: $table-row-hover;
            border-radius: 0px;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0;
            line-height: 22px;

            .accordion-arrow-down {
                float: right;
                font-size: 23px;
                -webkit-transition: transform 0.25s ease;
                -o-transition: transform 0.25s ease;
                transition: transform 0.25s ease;
            }

            .accordion-arrow-up {
                float: right;
                font-size: 23px;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition: transform 0.25s ease;
                -o-transition: transform 0.25s ease;
                transition: transform 0.25s ease;
            }
        }

        .no-margin-top {
            margin-top: 0px !important;
        }

        .minus-50px-margin-top {
            margin-top: -50px !important;
        }

        .carnet-card {
            border: 1px solid $default-cancel-gray;
            background-color: $toolbar-bg;
            padding: 30px;
            margin-bottom: 20px;

            .uil-trash:before {
                float: inherit;
                cursor: pointer;
            }

            .uil-atm-card:before {
                float: inherit;
            }

            .uil-award:before {
                float: inherit;
            }

            .delete-button {
                float: right;
                font-size: xxx-large;
            }
        }
    }

    .form-content {
        margin-left: 0px;
        margin-top: 24px;
        margin-right: 24px;

        .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-12 {
            padding-left: 0px;
        }

        .form-group {
            margin-bottom: 0px;
        }

        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        .no-margin-top {
            margin-top: 0px !important;
        }

        .no-margin-left {
            margin-left: 0px;
        }

        .no-margin-right {
            margin-right: 0px;
        }

        .no-margin-bottom {
            margin-bottom: 0px !important;
        }

        .detail-field {
            margin-top: 8px;
        }

        .form-group-small {
            width: 66px;
            float: left;
            margin-left: -10px;

            label {
                margin-right: 5px;
            }
        }

        .is-invalid {
            background-image: none !important;
        }

        .asterisc {
            color: $default-red;
            margin-left: 2px;
        }

        label {
            color: $default-gray;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
        }

        .required-field{
            border: 1px solid red;
        }
    }

    .contacts-toolbar {
        .btn-group {
            width: 98.5%;

            .btn {
                cursor: pointer;
                font-size: 15px;
                border-radius: 0px;
                box-sizing: border-box;
                border: 1px solid $default-cancel-gray;
                color: $disabled;
                background-color: $toolbar-bg;
                padding-top: 14px;
                padding-bottom: 14px;

                &:focus {
                    outline: 0 !important;
                }

                &:active {
                    outline: 0 !important;
                }
            }

            .active {
                &:focus {
                    outline: 0 !important;
                }

                &:active {
                    outline: 0 !important;
                }

                background-color: $default-red;
                color: $default-white;
                border: 1px solid $default-red;
            }
        }
    }

    .area-toolbar {
        .btn-group {
            width: 45%;
            margin-top: 36px;

            .btn {
                cursor: pointer;
                border-radius: 0px;
                box-sizing: border-box;
                border: none;
                border-bottom: 2px solid $default-cancel-gray;
                color: $disabled;
                background-color: transparent;
                padding-top: 14px;
                padding-bottom: 14px;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;

                &:focus {
                    outline: 0 !important;
                }

                &:active {
                    outline: 0 !important;
                }
            }

            .active {
                &:focus {
                    outline: 0 !important;
                }

                &:active {
                    outline: 0 !important;
                }

                border-bottom: 2px solid $default-red;
            }
        }
    }
}

.selectOption{
    &:focus{
        background-color: $default-red;
        color:$default-white;
    }
    &:hover{
        background-color: $default-red;
        color:$default-white;
    }
}

.scrollableList{
    max-height: 150px;
    overflow: overlay;
    margin-bottom: 20px;
}

.scrollableList-big{
    max-height: 250px;
    overflow: overlay;
    margin-bottom: 20px;
}

.simple-table{
    th, td{
        padding-top:8px;
        padding-bottom:8px;
        font-size: 12px;
    }
}

::-webkit-scrollbar {
    width: 8px;
}
  
::-webkit-scrollbar-track {
    background: $default-white; 
}
   
::-webkit-scrollbar-thumb {
    background: $default-cancel-gray; 
}
  
::-webkit-scrollbar-thumb:hover {
    background: $default-gray; 
}

.margin-top-30px{
    margin-top: 30px;
}

.table-content{
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;

    #pageDropDown{
        .btn-secondary{
            background-color: $default-gray;
            border-color: $default-gray;
        }
    }

    .dropdown-item{
        &:hover{
            background-color: $default-red;
            color:$default-white;
        }
    }

    .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid $default-cancel-gray;
        border-top: 1px solid $default-cancel-gray;
        color: $default-gray;
        font-weight: bold;
    }

    .table td{
        border-color: $default-cancel-gray;
        color: $table-row;
    }

    .table{
        tr:hover td{
            background-color: $table-row-hover;
            cursor: pointer;
        }
    }

    .page-item{
        color: $default-gray;
        &:focus{
            outline:none;
        }
      
        &:active{
            outline:none;
        }
    }

    .page-link{
        color: $default-gray;
        &:focus{
            outline:none;
        }
      
        &:active{
            outline:none;
        }
    }

    .page-item.active .page-link{
        z-index: 3;
        color: $default-white;
        background-color: $default-red;
        border-color: $default-red;
    }

    .sortable{
        &:focus{
            outline:none;
        }
      
        &:active{
            outline:none;
        }
    }
}