.tag {
  background-color: #676773 !important;
  color: #ffffff !important;
  margin: 2px;
  padding: 4px 4px 4px 8px;

  i {
    cursor: pointer;
    padding: 0 2px;
  }

  i:hover {
    background-color: rgba(114, 124, 245, 0.2) !important;
    color: #727cf5 !important;
  }
}

.tags-control {
  .placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
}
