//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  color: blue !important;
  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
    }
  }
  a {
    color: #0091ff !important;
  }
}
