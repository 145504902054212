//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.app-btn{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  box-sizing: border-box;
  height: 37px;
  width: auto;
  min-width: 110px;
  border-radius: 2.4px;
  cursor: pointer;

  &:focus{
      outline:none;
  }

  &:active{
      outline:none;
  }
}

.app-btn-functional{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  box-sizing: border-box;
  height: 37px;
  width: auto;
  border-radius: 2.4px;
  cursor: pointer;

  &:focus{
      outline:none;
  }

  &:active{
      outline:none;
  }
}

button:focus{
  &:focus{
    outline:none;
  }

  &:active{
      outline:none;
  }
}

.edit-btn{
  color: $default-gray;
  border: 1px dashed $default-cancel-gray;
  background-color: $default-cancel-gray;
  
  &:hover{
      background-color: $default-cancel-gray-hover;
      border: 1px dashed $default-cancel-gray-hover;
      color: $body-bg;
  }
}

.float-right{
  float: right;
  margin-right: 24px;
}

.cancel-btn{
  color: $default-gray;
  border: 1px dashed $default-cancel-gray;
  background-color: $default-cancel-gray;
  
  &:hover{
      background-color: $default-cancel-gray-hover;
      border: 1px dashed $default-cancel-gray-hover;
      color: $body-bg;
  }

  &:disabled{
    border: 1px dashed $default-cancel-gray;
    outline:none;
    color: $default-white;
    background-color: $default-cancel-gray;
    cursor: not-allowed;
  }
}

.export-icon{
  float: left;
  margin-right: 4px;
  font-size: large;
}

.import-icon{
  float: left;
  margin-right: 4px;
  font-size: large;
}

.excel-btn{
  width: 100px!important;
  border: 1px dashed $default-red;
  background-color: $default-red;
  color: $body-bg;
  margin-left: 10px;
  margin-bottom: 0px !important;
  margin-top: 10px;
  font-size: 12px;

  &:hover{
      box-shadow: 0 2px 6px 0 $default-red-shadow;
      background-color: $default-red;
      border: 1px dashed $default-red;
  }

  &:disabled{
    border: 1px dashed $default-cancel-gray;
    outline:none;
    color: $default-white;
    background-color: $default-cancel-gray;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.file-btn{
  opacity         : 0;
  position        : absolute;
  z-index         : -1;
  cursor          : pointer;
  font-size       : 12px;
}

.submit-btn{
  border: 1px dashed $default-red;
  background-color: $default-red;
  color: $body-bg;
  margin-left: 20px;

  &:hover{
      box-shadow: 0 2px 6px 0 $default-red-shadow;
      background-color: $default-red;
      border: 1px dashed $default-red;
  }

  &:disabled{
    border: 1px dashed $default-cancel-gray;
    outline:none;
    color: $default-white;
    background-color: $default-cancel-gray;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.modal-title{
    color: $default-gray;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 0.29px;
    line-height: 16px;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.py-05 {
  padding-top: ($spacer * 0.1);
  padding-bottom: ($spacer * 0.1);
}

@media print {
  .print-block {
    break-inside: avoid;
  }
}

img.background-pattern {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.background-pattern {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}

.th-baixa {
    background-color: #f7aeae;
   


    &:hover td {
        color: #b5b5b5 !important;
    }
}

.th-baixa td {
    color: #ffffff !important;
}

.btn-header{
    width: 100%!important;
    margin-left: 10px;
    margin-right: 0px;
}